<script setup lang="ts">
const props = defineProps({
  direction: {
    type: String,
    default: 'horizontal',
    validator(value: UiHeadingOption) {
      return ['horizontal', 'vertical'].includes(value)
    },
  },
})
</script>

<template>
  <div class="equal-spacing" :class="`equal-spacing--${props.direction}`">
    <slot />
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.equal-spacing {
  display: flex;
  .fluid(gap, 12, 24);

  &--vertical {
    flex-direction: column;
  }
}
</style>
